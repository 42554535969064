.subHeadStyles {
  font-size: 18px !important;
  font-weight: bold !important;
}

@media (min-width: 768px) {
  /* Apply these styles when the screen width is 768 pixels or larger (md size) */
  .subHeadStyles {
    font-size: 22px !important;
  }
}
.headerStyles {
  font-size: 20px !important;
  font-weight: bold !important;
}

@media (min-width: 768px) {
  /* Apply these styles when the screen width is 768 pixels or larger (md size) */
  .headerStyles {
    font-size: 24px !important;
  }
}
