/* VendorDetails.css */

/* Container styles */
.vendor-details-container {
  max-width: 980px;
  margin: 10px auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
}



/* Loading message styles */
.loading-message {
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  color: #555;
}

.dropdown {
  margin-top: 15px;
}

@media (max-width: 400px) {
  .vendor-details-container {
    max-width: 100%; /* Full-width for mobile */
    padding: 10px; /* Reduced padding for mobile */
  }

  .dropdown {
    margin-top: 20px; /* Reduced margin for mobile */
  }
}