/* VendorList.css */

/* Container styles */
.vendor-list-container {
  max-width: 1200px; 
  margin: 0 auto;
  padding: 20px;

  border-radius: 10px;
}

/* List item styles */
.vendor-list {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

/* Vendor name styles */
.vendor-name {
  flex-grow: 1;
  font-size: 18px;
}

.button-connect {
  margin-right: 10px;
}
/* 
VendorCard.css 
.vendor-card {
  width: 800px; 
  margin-bottom: 10px; /* Add some bottom margin for spacing 
} */

.vendor-status {
  font-weight: bold; /* Make the status text bold */
  margin-right: 10px; /* Add some margin to the right for spacing */
  color: #007bff; /* Set the text color to blue (you can change the color) */
}

.vendor-row:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
