.customContainer {
    padding: 20px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
  }

@media (min-width: 600px) {
    .customContainer {
      border: none;
    }
}